<template>
  <div class="editFalsePositiveZabbixEvent">
    <LoadingPlaceholder v-show="blockButton" />
    <form v-show="!blockButton">
      <div class="form-group">
        <h4 class="m-0 mt-3">
          False Positive Event
        </h4>
        <hr class="m-0 mb-3" />
        <div
          class="d-flex"
          style="justify-content: space-between"
        >
          <p class="mt-2">
            Selected Event: {{ problemId }}
          </p>
        </div>
        <span class="m-0 mb-3" />
        <label>{{ $t("description") }}</label>
        <textarea
          v-model="entry.description"
          class="form-control m-input m-input--air"
          name="Description"
          rows="5"
          placeholder="Please enter your description"
        />
        <div class="m-0 mb-3" />
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!entry.description || !problemId || blockButton
        "
        @click.prevent="editFalsePositiveZabbixEvent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t("edit") }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { authenticationMixin } from "@/mixins/authenticationMixin";
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";

export default {
  name: "EditFalsePositiveZabbixEvent",
  mixins: [kendoGridMixin, authenticationMixin],
  props: {
    requestObject: {
      type: Object,
      required: true,
    },
    problemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      blockButton: false,
      entry: {},
    };
  },
  created() {
    this.getEntry()
  },
  methods: {
    async getEntry() {
      this.blockButton = true;
      await this.axios
        .get("/SlaCalculation/GetFalsePositiveZabbixEntry?problemEventId=" + this.problemId)
        .then((response) => {
          if (response && response.status && response.status == 200) {
            this.entry = response.data;
          }
        })
        .finally(() => {
          this.blockButton = false;
        });
    },
    async editFalsePositiveZabbixEvent() {
      this.blockButton = true;
      let postObject = {
        ...this.entry,
        problemEventId: this.problemId
      }

      await this.axios
        .put("/SlaCalculation/EditFalsePositiveZabbixEvent", postObject)
        .then((res) => {
          if (res) {
            this.blockButton = false;
            this.$emit("recalculate");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
      this.blockButton = false;
    },
  },
};
</script>